

<template>
  <!-- date -->
  <v-row v-if="$root.admin || $root.manager">
    <v-col cols="12" class="text-center">
      <v-autocomplete
        :label="$t('form.view-as-user')"
        v-model="selectedUser"
        :items="Object.values(users)"
        item-text="displayName"
        item-value="id"
      >
        <template slot="selection" slot-scope="{ item }" v-if="selectedUser">
          {{ item.displayName }}
          <span class="emailText">({{ item.email }})</span>
        </template>
        <template slot="item" slot-scope="{ item, tile }">
          {{ item.displayName }}
          <span class="emailText"> ({{ item.email }})</span>
        </template>
      </v-autocomplete>
    </v-col>
  </v-row>
</template>

<script>
import { db, auth } from "@/main";
import { forEach, isNil, isEqual } from "lodash";
export default {
  name: "ViewAsUserSelection",
  data() {
    return {
      users: {},
      selectedUser: "",
    };
  },
  async mounted() {
    await this.getUsers();
    this.selectInitialUser();
  },
  watch: {
    selectedUser: function (newVal, oldVal) {
      if (!isEqual(oldVal, "")) {
        this.$emit("onUserSelection", this.users[newVal]);
      }
    },
  },
  methods: {
    async getUsers() {
      if (this.$root.admin || this.$root.manager) {
        let usersPromise = await db
          .collection("users")
          .where("role", "array-contains", "aider")
          .orderBy("displayName")
          .get();
        let usersObject = {};
        forEach(usersPromise.docs, (doc) => {
          let userData = doc.data();
          userData.id = doc.id;
          usersObject[userData.id] = userData;
        });
        this.users = usersObject;
      }
    },
    selectInitialUser() {
      if (!isNil(this.$root.currentUserData.userId)) {
        this.selectedUser = this.users[this.$root.currentUserData.userId];
      }
    },
  },
};
</script>
<style>
.emailText {
  font-size: 10px;
}
</style>